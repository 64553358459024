import React, {useEffect, Fragment} from 'react'
import "styles/style.scss"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// import { siteMetadata } from '../../gatsby-config'
import {Layout, EmailForm} from 'components'
import {Page} from 'types'
import jsonData from 'data'

const PriceTable = ({className}) => {

  const TableRow = ({adUnit, size, rate}) => (
    <tr>
      <td>{adUnit}</td>
      {size 
        ? <><td>{size}</td><td>{rate}</td></> 
        : <td colSpan={2} >{rate}</td>
      }
    </tr>
  )

  return(
    <table className={`table table-striped table-borderd table-sm table-hover ${className}`} >
      <thead>
        <TableRow adUnit='Ad Unit' size='Size' rate='Rate' />
      </thead>
      <tbody>
        {jsonData.text.partners.adPriceTable.map(({adUnit, size, rate}) => (
          <TableRow key={adUnit} adUnit={adUnit} size={size} rate={rate} />
        ))}
      </tbody>
    </table>
  )

}

export default ({ data }) => {

  const ruralImgProps = data.allFile.edges.filter(edge => edge.node.name === "star-phoenix-map-rural")[0].node.childImageSharp.fluid
  const stoonImgProps = data.allFile.edges.filter(edge => edge.node.name === "star-phoenix-map-saskatoon")[0].node.childImageSharp.fluid
  data.site.siteMetadata.title = `${data.site.siteMetadata.title} | Partners`  
  data.site.siteMetadata.siteUrl = `${data.site.siteMetadata.siteUrl}/partners`

  // useEffect(() => {
  //     window.scrollTo(0,0)
  // }, [data])

  return (
    <Layout siteMetadata={data.site.siteMetadata} page={Page.partners}>
        <div className='row w-100 pageContainer'>
          
          <div className='col-md-6 flex-center-col mt-md-0 mt-5' >  

            <div className='row'><h2 id='partnersTitle' >{jsonData.text.partners.numMagHeadline}</h2></div>

            <div className='row w-75 my-3 text-center'>
              
                <div className='col'>
                  {jsonData.text.partners.sellingPoints.map((sp, cnt) => (
                    <Fragment key={cnt}>
                      <small>{sp}</small> {/*TODO: Make line spacing smaller */}
                      {cnt !== jsonData.text.partners.sellingPoints.length - 1
                        ? <hr className='m-1' ></hr> : ''}  
                    </Fragment>
                  ))}
                </div>
              
            </div>

            <div className='row'><PriceTable className='bg-white col-md-12 col-11 m-auto' /></div>

          </div>

          <div className='col-md-6 mt-2' >

            <div className='row m-3 text-center' >{ jsonData.text.partners.starPhoenix }</div>

            <div className='row'>
              <div  className='col-sm-6 p-3'>
                <Img 
                  className='w-100 h-400px'
                  fluid={stoonImgProps}
                  fadeIn={false} 
                  alt="Saskatoon Star Phoenix route Saskatoon"
                  loading="eager"
                  imgStyle={{objectFit: 'contain' }}
                ></Img>
              </div>
              <div className='col-sm-6 p-3'>
                <Img 
                  className='w-100 h-400px'
                  fluid={ruralImgProps}
                  fadeIn={false} 
                  alt="Saskatoon Star Phoenix route rural Saskatchewan"
                  loading="eager"
                  imgStyle={{objectFit: 'contain' }}
                ></Img>
              </div>
            </div>

            <div className='row col-12 my-5'>
              <EmailForm className='col-11 m-auto' page={Page['partners']} />
            </div>
          
          </div>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PartnersPageQuery {
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, name: {regex: "/(star-phoenix-map-rural)|(star-phoenix-map-saskatoon)/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          name
        }
      }
    }
    site {
      siteMetadata {
        author
        authorDescription
        description
        title
        themeColor
        siteUrl
      }
    }
  }
  `
